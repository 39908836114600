<template>
    <div class="panel-category">
        <div class="filter-title">
            Category
        </div>
        <div class="filter-container">
            <div v-for="category in shopCategory">
                <input type="radio" name="category" :id="'category-' + category.id" v-model="option" :value="category.id">
                <label :for="'category-' + category.id">{{ category.name }}</label>
            </div>
        </div>

    </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import {useProductStore} from "../storages/product";

const store = useProductStore();
const shopCategory = ref([]);
const option = ref();

watch(option, async (newOption, oldOption) => {
    console.log(newOption);
    store.fetch({'shop_category_id': newOption}, () => {});
});

onMounted(() => {
    axios.get('/api/shopcategory').then((res) => {
        console.log(res.data);
        shopCategory.value = res.data;
    });
})

</script>

<style scoped>

</style>
