<template>
    <div class="leave-comment" id="leave-comment">
        <div>
            <h3>
                Leave A Comment
            </h3>
        </div>
        <div>
            <input type="hidden" v-model="articleId" />
            <textarea v-model="comment" placeholder="Write your comment here"></textarea>
        </div>
        <div class="button-container">
            <button @click="onCommentSubmitted()" :disabled="isSubmit === true" class="button-box">
                {{ isSubmit ? 'Please Wait..' : "Submit" }}
            </button>
        </div>
    </div>
    <popup-message-component @onClose="onClose()" :show="showPopup" :message="popupMessage"></popup-message-component>
    <signup-dialog-component ref="signup"></signup-dialog-component>
</template>

<script>
import PopupMessageComponent from "./PopupMessageComponent";
import SignupDialogComponent from "./SignupDialogComponent";

export default {
    props: [
        'articleId','token'
    ],
    components:[
        PopupMessageComponent,
        SignupDialogComponent
    ],
    data(){
        return {
            isSubmit: false,
            comment: '',
            showPopup: false,
            popupMessage: null
        }
    },
    methods: {
        onClose: function(){
            this.showPopup = false;
        },
        onCommentSubmitted: function(e){
            console.log('submitting comment');
            let t = this;

            //validation
            if(this.comment.length === 0){
                alert('Please enter your comment.');
                return false;
            }
            let payload = {
                comment: this.comment,
                article_id: this.articleId
            };

            this.isSubmit = true;
            axios.post('/api/v1/comment/user/send?token=' + this.token , payload).then((res)=> {
                console.log(res.data);
                this.showPopup = true;
                this.popupMessage = res.data.message;
            }).catch((error) => {
                if(error.response.status === 401){
                    this.showPopup = false;
                    t.$refs.signup.show();
                }else{
                    alert('Oops! We currently have technical issue, please try again later');
                }
            }).finally(() => {
                t.isSubmit = false;
                this.comment = '';
            });
        }
    },
    mounted() {
        console.log('Example component mounted.')
    }
}
</script>
