<template>
    <div class="section-product-reaction">
        <div v-if="showCallout" class="callout top">
            <a v-for="reaction in this.reactions" @click="sendReaction(reaction.id)">
                    <div class="reaction-smiley">
                        <img :src="'/assets/' + reaction.icon">
                        <span>{{ reaction.expression }}</span>
                        <small class="text-red data-count-reaction">{{ reaction.numberOfReaction }}</small>
                    </div>
                </a>
        </div>
        <div class="container" @click="onReactionBoxClicked()">
            <img :src="images.smiley" alt="smiley icon" />
            <span><span class="total-all-reaction"> {{ totalReaction }}</span> REACTIONS</span>
        </div>
        <div :class="isSubmitting ? 'visible' : 'hidden'" class="w-100 text-center mt-2 send-reaction-loading hidden">
            <small>
                WAIT FOR MOMENT PLEASE.. <i class='fa fa-spin fa-spinner'></i>
            </small>
        </div>
    </div>
    <popup-message-component @onClose="onPopupClosed()" :show="showPopup" :message="popupMessage"></popup-message-component>
    <signup-dialog-component ref="signup"></signup-dialog-component>
</template>

<script>
import PopupMessageComponent from "./PopupMessageComponent";
import SignupDialogComponent from "./SignupDialogComponent";

export default {
    name: "ReactionComponent",

    components:[
        PopupMessageComponent,
        SignupDialogComponent
    ],

    props:[
        'articleId',
        'token'
    ],

    data(){
        return {
            images:{
                smiley: "/assets/reaction-smiley.png"
            },

            reactions: [],
            isSubmitting: false,
            showCallout: false,
            showPopup: false,
            popupMessage: '',
            totalReaction: 0,
            articleIdLocal: null,
        }
    },

    methods: {
        sendReaction: function(reactionId) {
            let t = this;

            console.log('submitting reaction: ' + reactionId)
            this.isSubmitting = true;
            this.showCallout = false;

            let payload = {
                article_id: this.articleIdLocal,
                reaction_id: reactionId,
            }
            axios.post('/api/reaction/user/send?token=' + this.token, payload).then((res) => {
                console.log(res);
                this.showPopup = true;
                this.popupMessage = res.data.message;
                this.isSubmitting = false;

                //increment total reaction
                this.totalReaction += 1;

                //increment this particular reaction total
                this.reactions[reactionId-1].numberOfReaction += 1;

            }).catch((error) => {
                //unauthorized access, meaning not logged yet
                if(error.response.status === 401){
                    t.$refs.signup.show();
                }
            }).finally(() => {
                this.isSubmitting = false;
            });
        },

        onReactionBoxClicked: function(){
            this.showCallout = !this.showCallout;
        },

        onPopupClosed: function(){
            this.showPopup = false;
        },

        loadReactionData: function(articleId){
            axios.get('/api/reactions?articleId=' + articleId).then((res) =>{
                this.reactions = res.data.data;
                this.totalReaction = res.data.totalReaction;
            });
        }
    },

    mounted() {
        this.articleIdLocal = this.articleId;
        this.loadReactionData(this.articleIdLocal);
    },

    watch: {

        //just in case articleId is loaded late, get it again
        articleId: function(newVal){
            this.articleIdLocal = newVal;
            this.loadReactionData(this.articleIdLocal);
        }
    }
}
</script>

<style lang="scss" scoped>
.section-product-reaction {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
        padding: 0 5px;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        background-color: #E63C28;
        color: white;
        cursor: pointer;
    }

    .reaction-smiley {
        display: flex;
        flex-direction: column;
        width: auto;
        align-items: center;
        justify-content: center;
        color: black;
        cursor: pointer;

        img {
            width: 45px;
            height: 35px;
        }
    }


    .callout {
        height: fit-content;
        width: 350px;
        float: left;
        display: flex;
        justify-content: space-between;
    }

    .callout {
        background-color: white;
        position: absolute;
        bottom: 0;
        color: #ccc;
        padding: 25px;
        border-radius: 15px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
        margin-bottom: 105px;
        min-height: 50px;
    }

    .callout::before {
        content: "";
        width: 0px;
        height: 0px;
        border: 0.8em solid transparent;
        position: absolute;
    }

    .callout.top::before {
        left: 45%;
        bottom: -20px;
        border-top: 10px solid white;
    }

    .visible{
        visibility: visible;
    }

    .hidden{
        visibility: hidden;
    }
}
</style>
