<template>
    <div v-if="myLikeStatus === 0">
        <div v-if="likeStatus === 'init'" class="like" @click="onLikeClicked()">
            LIKE <span>{{ likeNumber }}</span>
        </div>
        <div v-else-if="likeStatus === 'submitting'" class="like" @click="onLikeClicked()">
            Submitting...
        </div>
        <div v-else class="liked">
            LIKED <span>{{ parseInt(likeNumber) + 1}}</span>
        </div>
    </div>
    <div v-else class="liked">
        LIKED <span>{{ likeNumber }}</span>
    </div>


    <popup-message-component @onClose="onClose()" message="Comment is liked" :show="showPopup"></popup-message-component>
    <signup-dialog-component ref="signup"></signup-dialog-component>
</template>

<script>
import PopupMessageComponent from "./PopupMessageComponent";
import SignupDialogComponent from "./SignupDialogComponent";

export default {
    name: "CommentLikeComponent",
    components: [
        PopupMessageComponent,
        SignupDialogComponent
    ],
    props: [
        'commentId','articleId','token','myLikeStatus','likeNumber'
    ],
    data(){
        return {
            showPopup: false,
            likeStatus: 'init'
        }
    },

    methods: {
        onLikeClicked: function (){
            let t = this;
            console.log('like is clicked');
            this.likeStatus = 'submitting';

            let payload = {
                comment_id: this.commentId,
                article_id: this.articleId
            };

            axios.post('/api/v1/comment/like/user/send?token=' + this.token, payload).then((res)=> {
                console.log(res.data);
                this.showPopup = true;
                this.popupMessage = res.data.message;
                this.likeStatus = 'liked';
            }).catch((error) => {
                //unauthorized access, meaning not logged yet
                if(error.response.status === 401){
                    t.$refs.signup.show();
                }
            }).finally(() => {
                this.likeStatus = 'init';
            });
        },

        onClose: function(){
            this.showPopup = false;
        }
    },

    mounted() {
        console.log('mylikestatus: ' + this.myLikeStatus);
    },
}
</script>

<style scoped>
    .like{
        margin-top: 10px;
        cursor: pointer;
    }

    .like:hover{
        color: #E63C28;
    }

    .liked{
        margin-top: 10px;
        color: #E63C28;
    }
</style>
