<template>
    <div class="component-container">
        <div v-if="!isStart">
            <img class="image" :src="personalityQuiz.image" />
            <div class="btn-container">
                <button @click="onStart()" class="btn btn-primary">Start Now</button>
            </div>

            <h2>{{ personalityQuiz.title }}</h2>
            <p class="description">{{ personalityQuiz.description }}</p>
        </div>
        <div v-else>
            <div v-if="isCompleted">
                <div v-if="submitCompleted">
                    <img class="image" :src="'/storage/' +result.image" />
                    <h2>{{ result.title }}</h2>
                    <p class="description">{{ result.description }}</p>
                </div>
                <div v-else>
                    Submitting...
                </div>
            </div>
            <div v-else>
                <img class="image-question" :src="activeQuestion.image" />
                <h3 class="text-center pt-5">{{ activeQuestion.question }}</h3>
                <div class="option-container">
                    <div class="option-item" v-for="option in activeQuestion.options" @click="onOptionClicked(activeQuestion.id, option.id)">
                        <div class="item-container">
                            <img :src="option.image" />
                            <div class="option">{{ option.option }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <signup-dialog-component ref="signup"></signup-dialog-component>

</template>

<script>
import SignupDialogComponent from "./SignupDialogComponent";

export default {
    name: "PersonalityQuizComponent",
    components: [
      SignupDialogComponent
    ],
    props: ["token","personalityQuizId"],
    data(){
        return {
            localPersonalityQuizId: null,
            isStart: false,
            personalityQuiz:{
                questions: [],
                results:[]
            },
            activeQuestion: {},
            activeQuestionIndex: 0,
            isCompleted: false,
            submitCompleted: false,
            answers: [],
            result:[]
        }
    },

    methods: {
        onStart: function(){
            this.isStart = true;
        },
        onOptionClicked: function(questionId, optionId){

            this.answers.push({
                questionId,
                optionId
            });

            //a bit of delay to improve UX
            setTimeout(()=> { this.nextQuestion(); },300)
        },

        onQuizCompleted: function(answers, quizId){
            console.log('Quiz completed');

            this.isCompleted = true;

            let payload = {
                answers,
                quizId,
                token: this.token
            }

            let t = this;
            axios.post('/api/v1/personality-quiz/' + quizId + '/result', payload).then((res) => {
                console.log(res.data);
                this.result = res.data;
            }).catch((error) => {
                //unauthorized access, meaning not logged yet
                if(error.response.status === 401){
                    t.$refs.signup.show();
                    this.restartQuiz();
                }
            }).finally(() => {
                this.submitCompleted = true;

            });

        },

        nextQuestion: function(){
            if(this.activeQuestionIndex < this.personalityQuiz.totalQuestion - 1){
                this.activeQuestionIndex += 1;
                this.activeQuestion = this.personalityQuiz.questions[this.activeQuestionIndex];
            }else{
                this.onQuizCompleted(this.answers, this.localPersonalityQuizId);
                this.isCompleted = true;
            }

        },

        restartQuiz: function(){
            this.isCompleted = false;
            this.submitCompleted = false;
            this.isStart = false;
            this.answers = [];
            this.activeQuestionIndex = 0;
            this.activeQuestion = this.personalityQuiz.questions[this.activeQuestionIndex];
        }
    },

    mounted() {
        this.isStart = false;

        this.localPersonalityQuizId = this.personalityQuizId;

        axios.get('/api/personality-quiz/' + this.localPersonalityQuizId + '?token=' + this.token).then((res) => {
           console.log(res.data);
           this.personalityQuiz = res.data;
           this.activeQuestion = res.data.questions[this.activeQuestionIndex];
        });
    }

}
</script>

<style lang="scss" scoped>
    .image{
        width: 100%;
    }

    h2{
        text-align: center;
        margin-top: 40px;
        display: block !important;
    }

    h3{
        margin-bottom: 50px;

    }

    p.description{
        text-align: center;
        font-size: 18px !important;
        font-family: Georgia-Regular, sans-serif;
        padding-bottom: 50px;
    }

    .component-container{
        margin: 20px 0;
        background: rgb(210, 235, 230);
        padding: 25px;

    }

    .btn-container{
        text-align: center;
    }

    .btn-primary{
        background: #E63C28 !important;
        border: 0;
        padding: 15px 50px;
        margin-top: -20px;
        box-shadow: 0 2px 25px #666;
        font-size: 22px;
        font-weight: bold;
    }

    .image-question{
        width: 100%;
        height: 240px;
        object-position: center;
        object-fit: cover;
    }

    .option-container{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        .option-item{
            width: 115px;
            padding: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
            background: white;
            text-align: center;
            cursor: pointer;
            font-size: 14px;

            .item-container{
                img{
                    width: 100px;
                    height: 100px;
                    margin-bottom: 5px;
                    object-fit: cover;
                    object-position: center;
                }
            }

        }
    }

    .option-item:active{
        background: #E63C28;
        color: white;
    }



    @media (max-width: 767px) {
        .option{
            font-size: 10px;
            margin-top: 4px;
        }
    }
</style>
