<template>
    <span v-if="isSubmitting" class="submit-bookmark-loading">
        <img src="https://cosmopolitan.co.id/assets/mra-icon-loading.gif">
    </span>
    <span v-else>
        <img v-if="isBookmarked === true" src="/assets/mra-icon-article-unsave.png" class="icon-bookmark">
        <img v-else src="/assets/mra-icon-article-save.png" class="icon-bookmark" @click="bookmarkThis()">
    </span>
    <signup-dialog-component ref="signup"></signup-dialog-component>
    <popup-message-component ref="popup"></popup-message-component>
</template>

<script>
import SignupDialogComponent from "./SignupDialogComponent";
import PopupMessageComponent from "./PopupMessageComponent";

export default {
    name: "BookmarkComponent",
    components: [
        SignupDialogComponent,
        PopupMessageComponent
    ],
    props: [
      'articleId','token','bookmarkStatus'
    ],

    data(){
        return {
            isBookmarked: false,
            isSubmitting: false,
            articleIdLocal: null,
        }
    },

    methods: {
      bookmarkThis: function(){

          let t = this;

          console.log('bookmarking...');
          this.isSubmitting = true;

          let payload = {
              article_id: this.articleIdLocal,
          }
          axios.post('/api/v1/bookmark/user/send?token=' + this.token, payload).then((res) => {
              console.log(res.data);
              this.isBookmarked = true;
              t.$refs.popup.setMessage(res.data.message);
              t.$refs.popup.showPopup();

          }).catch((error) => {
              console.log(error.response.status);
              if(error.response.status === 401){
                  this.showPopup = false;
                  t.$refs.signup.show();
              }else{
                  alert('Oops! We currently have technical issue, please try again later');
              }
          }).finally(() => {
            this.isSubmitting = false;
          });
      }
    },

    mounted() {
        console.log('bookmark status: ' + this.bookmarkStatus);
        this.isBookmarked = this.bookmarkStatus;
        this.articleIdLocal = this.articleId;
    },

    watch: {
        //because it's async, the initial value should be set here just in case the value is not loaded into this component yet
        bookmarkStatus: function(newValue){
            this.isBookmarked = newValue;
        },

        articleId: function(newValue){
            this.articleIdLocal = newValue;
        }
    }
}
</script>

<style scoped>
    .icon-bookmark{
        width: 35px !important;
        height: 35px !important;
    }
</style>
