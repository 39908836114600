require('./bootstrap');

import { createApp } from 'vue';
import { createPinia } from 'pinia'

let app=createApp({})
app.component('comment-component' , require('./components/CommentComponent.vue').default);
app.component('popup-message-component', require('./components/PopupMessageComponent').default);
app.component('comment-like-component', require('./components/CommentLikeComponent').default);
app.component('reaction-component', require('./components/ReactionComponent').default);
app.component('signup-dialog-component', require('./components/SignupDialogComponent').default);
app.component('bookmark-component', require('./components/BookmarkComponent').default);
app.component('article-detail-component', require('./components/ArticleDetailComponent').default);
app.component('article-loader-component', require('./components/ArticleLoaderComponent').default);
app.component('trivia-component', require('./components/TriviaComponent').default);
app.component('survery-component', require('./components/TriviaComponent').default);
app.component('personality-quiz-component', require('./components/PersonalityQuizComponent').default);
app.component('product-list-component', require('./components/ProductListComponent').default);
app.component('product-filter-brand-component', require('./components/ProductFilterBrandComponent').default);
app.component('product-filter-category-component', require('./components/ProductFilterCategoryComponent').default);
app.component('add-to-cart-component', require('./components/AddToCartComponent').default);
app.component('shopping-cart-component', require('./components/ShoppingCartComponent').default);

const WOW = require('wowjs');
var reactionPopup;

$(document).ready(function () {

    const pinia = createPinia()
    app.use(pinia)

    app.mount("#vue-app")

	// wow.js init
	window.wow = new WOW.WOW({
		// settings here
	});
	wow.init();

	//slider slick init
	$('.slider').slick({
		autoplay: true,
		autoplaySpeed: 2500,
		dots: true,
		arrows: false,
		infinite: true
	});

	$('.slider-single-item').slick({
		dots: false,
		prevArrow: $('#slideLeft'),
		nextArrow: $('#slideRight'),
		variableWidth: true,
		responsive: [
			{
				//breakpoint: 768,
				breakpoint: 1099,
				settings: {
					slidesToShow: 1,
					variableWidth: false,
					autoplay: true
				}
			}
		]
	});

	/*reactionPopup = document.getElementsByClassName('callout')[0];
	if (reactionPopup != null) {
		reactionPopup.style.visibility = "hidden";

		document.getElementsByClassName('section-product-reaction')[0].onclick = function () {
			if (reactionPopup.style.visibility == "hidden")
				reactionPopup.style.visibility = "visible";
			else
				reactionPopup.style.visibility = "hidden";
		};

	}*/

	UpdateSkinAdsContainerPos();
	$(window).scroll(function () {
		UpdateSkinAdsContainerPos();
	});

});

function UpdateSkinAdsContainerPos() {
	var pos = 120 + $("#section-navigation").offset().top;
	var max = $("#main-container").height() - 1220;
	var min = 0;

	if (window.location.href.indexOf("/channel") > -1) {
		min = 580;
	}

	if (window.location.href.indexOf("/shop") > -1) {
		min = 580;
	}

	if (window.location.href.indexOf("/shop-cart") > -1) {
		min = 0;
		max = $("#main-container").height() - 300;
	}

	if (window.location.href.indexOf("/profile") > -1) {
		min = 600;
	}

	if (pos <= min)
		pos = min;

	if (pos >= max)
		pos = max;

	$('#skin-ads-container').css('top', pos + "px");
}

function forceWowReanimation(element) {
	element.classList.remove('animated');
	element.style.removeProperty('animation-iteration-count');
	element.style.removeProperty('animation-delay');
	element.style.removeProperty('animation-iteration-count');
	element.style.removeProperty('animation-name');
	wow.applyStyle(element, true);
	setTimeout(function () {
		wow.show(element);
	}, 10);
}

/*$(document).ready(function(){

	$(window).scroll(function () {
        if ($(window).scrollTop() + $(window).height()+500 >= $(document).height()) {
            $('.data-hide[style*="display: none"]:first').fadeIn('slow');
        }
    });

	function infinteLoadMore(){
    	const related = $("#channel_id").val(), article_id = $("#article_id").val();
    	var newId = $(".newId:last").val();
        $.ajax({
            url: "/related-article",
            datatype: "html",
            data: {related: related, id: article_id,newId:newId},
            type: "get",
            beforeSend: function () {
                $('.auto-load').show();
            }
        })
        .done(function (response) {
            if (response.length == 0) {
                $('.auto-load').html("We don't have more data to display :(");
                return;
            }
            $('.auto-load').hide();
            $("#data-wrapper").append(response);
        })
        .fail(function (jqXHR, ajaxOptions, thrownError) {
            console.log('Server error occured');
        });
    }
});*/
