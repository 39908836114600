<template>
    <div v-if="isShow === true" class="popup" >
        <div class="container">
            <div class="title">
                <span>{{ this.popupMessage }}</span>
            </div>
            <div class="search-container" @click="onClose();">
                <span  class="button-box">
                    CLOSE
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopupMessageComponent",
    props: [
        'show','message'
    ],
    data(){
        return {
            isShow: false,
            popupMessage: null
        }
    },

    methods:{
        onClose: function(){
            this.isShow = false;
            this.$emit('onClose');
        },

        close: function(){
            this.isShow = false;
        },

        showPopup: function(){
            this.isShow = true;
        },

        setMessage: function(message){
            this.popupMessage = message;
        }
    },

    mounted() {
        this.isShow = this.show;
        this.popupMessage = this.message

    },

    watch: {
        show: function(newVal, oldVal){
            this.isShow = newVal;
        },
        message: function(newVal, oldVal){
            this.popupMessage = newVal;
        },

    }

}
</script>

<style lang="scss" scoped>
    .popup{
        position: fixed;
        width: 100vw;
        height: 100vh;
        margin: 0;
        padding: 0;
        top: 0;
        left: 0;
        background: rgba(255,255,255,0.8);
        z-index: 100000;
        display: flex;
        justify-content: center;
        justify-items: center;
        flex-direction: column;

        .container{
            text-align: center;
            padding: 20px;
            width: 500px;
            height: 300px;
            background-color: white;
            border-radius: 15px;
            box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .title {
                font-size: 24px;
                color: black;

                padding-bottom: 50px;
            }
        }

        @media (max-width: 767px) {
            .container{
                width: 80%;
            }
        }
    }
</style>
