<template>
    <div v-if="isLoaded">

        <section class="section-ad-banner hide-on-desktop">
            <div class="container no-padding">    
                <div class="content-ad-banner">
                    <div id='div-billboard'>                                
                    </div>
                </div>
            </div>
        </section>

        <section id="section-article-highlight">
            <div class="content breadcrumb">
                <div class="content-breadcrumb">
                    <a :href="'/' + article.channel.slug">{{ article.channel.name }}</a>
                    <span class="arrowhead">&#8250;</span>
                    <a :href="'/' + article.subChannel.slug">{{ article.subChannel.name }}</a>
                </div>
            </div>

            <div class="content title">
                <h1 class="article-title" v-bind:data-id="article.id" v-bind:data-url="article.url" :data-title="article.title" :data-channel="article.channel.name">
                    {{ article.title }}
                </h1>
            </div>

            <div class="content writter">
                BY <a :href="'/' + article.user.slug">{{ article.user.name }}</a>
                <span class="dot-separator">&#9679;</span>
                {{ article.created_at }}
            </div>

            <div class="content share-icon">
                <bookmark-component :article-id="article.id" :token="token" :bookmark-status="article.bookmarkStatus"></bookmark-component>
                <span data-bs-toggle="modal" data-bs-target="#modal-article-share">
					<img src="/assets/mra-icon-article-share.png">
				</span>
            </div>

            <div class="content">
                <img :src="article.image">
            </div>

            <div class="content caption-main" v-html="article.content"></div>
            <div class="content fw-bold" v-html="article.accreditation"></div>
        </section>

        <section class="section-product-content numbered-article">
            <div v-for="numberedArticle in article.numberedArticles" class="container">
                <span class="numbering">{{ numberedArticle.number }}</span>
                <div class="title">
                    <h3>
                        {{ numberedArticle.title }}
                    </h3>
                </div>
                <img :src="numberedArticle.image"/>

                <div class="caption" v-html="numberedArticle.content"></div>

                <a v-if="numberedArticle.text_in_box" class="prize-btn" target=_blank :href="numberedArticle.url_slug">
                    {{ numberedArticle.text_in_box }}
                </a>
                <h5 v-if="numberedArticle.additional_text">
                    {{ numberedArticle.additional_text }}
                </h5>
            </div>
        </section>


        <section class="text-grey">
            <div class="container">
                Tags: <span v-for="tag in this.article.tags">&nbsp;<a :href="'/' + tag.slug">{{ tag.name.en }}</a>,</span>
            </div>
        </section>

        <personality-quiz-component  v-if="article.enable_personality_quiz" :personality-quiz-id="article.personalityQuizId" :token="token"></personality-quiz-component>

        <trivia-component v-if="article.enable_trivia" :trivia-id="article.triviaId" :token="token"></trivia-component>

        <survey-component v-if="article.enable_survey" :survey-id="article.surveyId" :token="token"></survey-component>
        
        <section class="section-ad-banner hide-on-desktop">
            <div class="container">    
                <div class="content-ad-banner">
                    <div id='div-gpt-ad-mr1'>                                
                    </div>
                    <div id='div-gpt-ad-mr2'>                                
                    </div>
                </div>
            </div>
        </section>

        <section class="section-ad-banner hide-on-mobile">
            <div class="container">    
                <div class="content-ad-banner">
                    <div id='div-gpt-ad-temp-multi'>                                
                    </div>
                </div>
            </div>
        </section>

        <reaction-component v-if="article.enable_reaction" :article-id="article.id" :token="token"></reaction-component>
        
        <section v-if="article.enable_comment" class="section-comments" id="comments">
            <h2><span class="underline-wave" style="background: url('/assets/icon-underline.png') bottom repeat-x;">
					Comments
				</span></h2>

            <div class="content">
                <div v-for="comment in article.comments" class="row comment-item">
                    <div class="col-2 col-sm-2 col-md-2">
                        <img :src="comment.userPicture" class="comment-picture"/>
                    </div>
                    <div class="col-10 col-sm-10 col-md-10">
                        <div class="row">
                            <div class="col-6 col-lg-8 comment-name">
                                {{ comment.name }}
                            </div>
                            <div class="col-6 col-lg-4 comment-date">
                                {{ comment.createdAt }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col comment-content">
                                {{ comment.comment }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col comment-like">
                                <comment-like-component
                                    :token="token"
                                    :article-id="article.id"
                                    :comment-id="comment.id"
                                    :my-like-status="comment.isLiked"
                                    :like-number="comment.totalLike"
                                >
                                </comment-like-component>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <comment-component
                :article-id="article.id"
                :token="token">
            </comment-component>
        </section>

        <!-- <section class="section-ad-banner">
            <div class="container">    
                <div class="content-ad-banner">
                    <div id='div-gpt-ad-temp-multi'>                                
                    </div>
                </div>
            </div>
        </section>

        <section class="section-ad-banner">
            <div class="container">    
                <div class="content-ad-banner">
                    <div id='div-gpt-ad-mr1'>                                
                    </div>
                    <div id='div-gpt-ad-mr2'>                                
                    </div>
                </div>
            </div>
        </section> -->
        
        <div class="related-articles">
            <div class="format-4x1">
                <div class="col title">
                    <span class="underline-wave wavy-underline">
                        Related Articles                        
                    </span>
                </div>

                <div class="row content">
                    <div v-for="relatedArticle in article.relatedArticles" class="col col-6">
                        <div class="img-container">
                            <a :href="relatedArticle.url">
                                <img class="img-fill-content" :src="relatedArticle.image">
                            </a>
                        </div>
                        <div class="div-margin">
                            <a :href="'/' + relatedArticle.subChannel.slug"><span class="highlight-tag">{{ relatedArticle.subChannel.name }}</span></a>
                        </div>
                        <div class="content-title">
                            <a :href="relatedArticle.url">{{ relatedArticle.title }}</a>
                        </div>
                        <div class="content-writer">
                            <a :href="'/' + relatedArticle.user.slug">{{ relatedArticle.user.name }}</a>
                        </div>
                    </div>    
                    <div class="row">
                        <!--TABOOLA RELATED ARTICLES-->
                        <!-- <div id="taboola-below-article-thumbnails"></div>                 -->
                    </div>
                </div>
            </div>

        </div>
        <hr />
        <hr class="trigger-article-loader">
    </div>
    <div class="loading-article" v-else>
        <div><i class="fa fa-spin fa-spinner"></i> Loading Article...</div>
    </div>

    

</template>

<script>
import TriviaComponent from "./TriviaComponent";
import SurveyComponent from "./SurveyComponent";
import PersonalityQuizComponent from "./PersonalityQuizComponent";
const { adsOnIn } = require('../adsOnInfinitescroll');

export default {
    name: "ArticleDetailComponent",
    components: {PersonalityQuizComponent, SurveyComponent, TriviaComponent},
    props:[
      'articleId','token'
    ],
    data() {
        return {
            article: {
                channel: {},
                subChannel: {},
                user: {},
                tags: [],
                comments: []
            },
            isLoaded: false,
        }
    },
    mounted() {
        axios.get('/api/article/' + this.articleId + '?token=' + this.token).then((res) => {
            console.log("Article Data: ");
            //console.log(res.data);            
            this.article = res.data;
            this.isLoaded = true;
            this.$emit('onLoaded', res.data);

            //Fix instagram not rendered properly
            setTimeout(function () {
                if (typeof window.instgrm !== 'undefined') {
                    window.instgrm.Embeds.process();
                }                

                var uri = res.data.channel.name;
                var myid = res.data.id;
                adsOnIn.adsOnInfinitescroll(uri,myid);                
            }, 500);                       
        });
    },
}
</script>

<style scoped>
.content-breadcrumb {
    text-transform: uppercase;
}

.writer {
    text-transform: uppercase;
}

.comment-item {
    margin-top: 30px;
}

.loading-article {
    text-align: center;
}

.comment-picture {
    width: 80px;
    border-radius: 40px;
    border: 1px solid #E63C28;
}

section.numbered-article .container {
    margin-bottom: 60px;
}

.title{
    margin-left: 8px;
}

@media (max-width: 767px) {
    .comment-picture {
        width: 50px;
    }
}
</style>
