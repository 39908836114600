<template>
    <div class="container-cart">
        <div v-if="isEmpty">
            <div class="m-3 p-5 text-center text-red">
                No item on the shopping cart
            </div>

        </div>
        <div v-else>
            <div v-if="isError" class="mx-4 my-4 alert alert-danger">Sorry one of the product is out of stock</div>
            <div v-for="(product, index) in products" class="per-section cart-content">
                <div class="img-container">
                    <img class="img-fill-content" :src="product.product.image">
                </div>
                <div class="data-container">
                    <div class="name">
                        {{ product.product.name }}
                    </div>
                    <div class="quantity">
                        <img @click="min(index, product.id, product.quantity)" class="btn" src="/assets/Minus.png">
                        {{ product.quantity }}
                        <img @click="add(index, product.id, product.quantity)" class="btn" src="/assets/Plus.png">
                    </div>
                    <div class="price">
                        <div>Rp.{{ product.price_formatted }}</div>
                        <small class="text-gray">Stock Available {{ product.product.stock }} item(s)</small>
                    </div>
                </div>
            </div>
        </div>

        <hr>

        <div v-if="!isEmpty">
            <div v-if="isEligibleForTransaction">
                <div class="per-section cart-subtotal">
                    <div class="content">
                        <div class="label">
                            Subtotal
                        </div>
                        <div class="price">
                            {{ numberWithCommas(subTotal) }}
                        </div>
                    </div>
                    <div class="content">
                        <div class="label">
                            <div class="font-bold">Shipping Fee</div>
                            <div class="text-sm">{{ shipmentInfo.logistic_name }} ({{ shipmentInfo.min_day }} - {{ shipmentInfo.max_day }} days)</div>

                        </div>
                        <div class="price">
                            {{ numberWithCommas(shipmentInfo.shipment_cost) }}
                        </div>
                    </div>
                    <div class="content">
                        <div class="label">
                            <div class="font-bold">Insurance Fee</div>
                        </div>
                        <div class="price">
                            {{ numberWithCommas(shipmentInfo.insurance_cost) }}
                        </div>
                    </div>
                </div>
                <hr>

                <div class="per-section cart-total">
                    <div class="content">
                        <div class="label">
                            Total
                        </div>
                        <div class="price">
                            {{ numberWithCommas(grantTotal) }}
                        </div>
                    </div>
                </div>

                <div class="per-section button-payment">
                    <button :disabled="isLoading" @click="pay" class="button-box">
                        PROCESS PAYMENT
                    </button>
                </div>
            </div>
            <div v-else>
                <div class="text-center text-red mx-3 mt-4">Sorry we can't find any delivery service for your transaction.</div>
            </div>
        </div>
        <div v-else>

        </div>



        <div v-if="isLoading" class="loading-cover">
            Loading...
        </div>
    </div>

</template>

<script setup>
import {ref, watch} from "vue";

    const props = defineProps(['token']);
    const products = ref([]);
    const subTotal = ref(0);
    const grantTotal = ref(0);
    const isLoading = ref(false);
    const shipmentInfo = ref([]);
    const isError = ref(false);
    const isEmpty = ref(true);
    const isEligibleForTransaction = ref(true);


    fetch();

    function pay(){
        isLoading.value = true;

        axios.post('/api/v1/payment?token=' + props.token, {}).then((res) => {
            console.log(res.data);
            location.replace(res.data.redirectUrl);
        }).catch((error) => {
            if(error.response.status === 422){
                isError.value = true;
            }
        }).finally(()=>{
            isLoading.value = false;
        });
    }


    function add(index, cartId, oldQuantity){

        isLoading.value = true;
        products.value[index].quantity++;
        const newQuantity = oldQuantity + 1;
        axios.post('/api/v1/shopping-cart/'+ cartId + '?token=' + props.token, { quantity: newQuantity }).then((res) => {
            console.log(res.data);
            fetch();
        });
    }

    function min(index, cartId, oldQuantity){
        if(products.value[index].quantity > 0){
            isLoading.value = true;
            products.value[index].quantity--;
            const newQuantity = oldQuantity - 1;
            axios.post('/api/v1/shopping-cart/'+ cartId + '?token=' + props.token, { quantity: newQuantity }).then((res) => {
                console.log(res.data);
                fetch();
            });
        }
    }

    function fetch(){
        isLoading.value = true;
        axios.get('/api/v1/shopping-cart?token=' + props.token).then((res) => {
            console.log(res.data);

            if(res.data.items){
                isEmpty.value = false;
                products.value = res.data.items;
                subTotal.value = res.data.total;
                grantTotal.value = res.data.grand_total;
                shipmentInfo.value = res.data.shipment_info;
                isEligibleForTransaction.value = res.data.is_transaction_eligible;
            }

            isLoading.value = false;
        })
    }

    function numberWithCommas(x) {
        if(x) return 'Rp.'+x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
</script>

<style scoped>
    small{
        font-size: 12px;
    }

    .loading-cover{
        background: rgba(0,0,0,0.5);
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        color: white;
    }
</style>
