<template>
    <div class="panel-brand">
        <div class="filter-title">
            Brand
        </div>
        <div class="filter-container">
            <div v-for="brand in shopBrands">
                <input type="radio" name="brand" :id="'brand-' + brand.id" v-model="option" :value="brand.id">
                <label :for="'brand-' + brand.id">{{ brand.name }}</label>
            </div>

        </div>
    </div>
</template>

<script setup>

import {onMounted, ref, watch} from "vue";
import {useProductStore} from "../storages/product";

const store = useProductStore();
const shopBrands = ref([]);
const option = ref();

watch(option, async (newOption, oldOption) => {
    console.log(newOption);
    store.fetch({'shop_brand_id': newOption}, () => {});
});

onMounted(() => {
    axios.get('/api/shopbrand').then((res) => {
       console.log(res.data);
       shopBrands.value = res.data;
    });
})


</script>

<style scoped>

</style>
