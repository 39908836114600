<template>
    <div v-if="hasSurvey">
        <div v-if="isLoaded">
            <section class="section-article-poll-question" id="polling">
                <div class="container">
                    <div class="question">
                        {{ survey.question }}
                    </div>


                    <div class="survey-option-list">

                        <div class="answer send-survey">
                            <div v-if="!isSubmitting">
                                <div v-if="!isSubmitted" v-for="option in survey.options" @click="onOptionClicked(option.id)" class="answer">
                                    <span>
                                        {{ option.option }}
                                    </span>
                                </div>

                                <div v-for="result in surveyResult" v-else class="progress answer p-0 position-relative result-survey">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + result.votePercentage + '%'">
                                    </div>
                                    <div class="justify-content-center d-flex position-absolute show-percentage">{{ result.option }} ( {{ result.votePercentage }}% )</div>
                                </div>
                            </div>
                            <div v-else>
                                <i class='fa fa-spin fa-spinner'></i> Submitting your answer...
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <signup-dialog-component ref="signup"></signup-dialog-component>
        </div>
        <div v-else class="loading-quiz">
            <i class="fa fa-spin fa-spinner"></i> Loading Survey...
        </div>
    </div>
</template>

<script>
export default {
    name: "SurveyComponent",

    props: ['surveyId', 'token'],

    data(){
        return {
            localSurveyId: null,
            survey: {
                options: []
            },
            surveyResult: {
                options: []
            },

            isSubmitting: false,
            isSubmitted: false,
            isLoaded: false,
            hasSurvey: true,
        }
    },

    methods:{
        onOptionClicked: function(optionId){
            let t = this;

            console.log('Choose: ' + optionId);
            this.isSubmitting = true;

            let payload = {
                optionId: optionId,
                surveyId: this.localSurveyId,
                token: this.token
            };

            axios.post('/api/v1/survey/user/send', payload).then((res) => {
                console.log(res.data);
                this.surveyResult = res.data;
                this.isSubmitted = true;
            }).catch((error) => {
                //unauthorized access, meaning not logged yet
                if(error.response.status === 401){
                    t.$refs.signup.show();
                    this.isSubmitted = false;
                }
            }).finally(() => {
                this.isSubmitting = false;
            });
        }
    },

    mounted() {
        this.localSurveyId = this.surveyId;
        this.hasSurvey = !isNaN(parseInt(this.localSurveyId));

        if(this.hasSurvey){
            axios.get('/api/survey/'+ this.localSurveyId + '?token=' + this.token ).then((res) => {
                console.log(res.data);
                this.survey = res.data;
                this.isLoaded = true;
            });
        }


    }
}
</script>

<style scoped>
    .progress-bar{
        background: #E63C28;
    }

    .result-survey{
        min-height: 57px;
        height: auto;
        background: white;
    }

    .show-percentage{
        margin:15px;
        width: calc(100% - 30px);
    }
</style>
