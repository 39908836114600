const gaCosmo = {
    sendPageview: (url, title) => {

        if(ga === null){
            throw 'Please configure you GA properly, the ga variable should be global';
        }

        ga(function(tracker) {
            let clientId = tracker.get('clientId');
            if(clientId === null){
                throw 'You need to call ga("create") globally and set the tracker ID';
            }
        });

        let urlObject = new URL(url);
        let pathName = urlObject.pathname;

        let payload = {
            'page': pathName,
            'location': url,
            'title': title
        }
        console.log(payload);
        ga('send', 'pageview', payload);


    }
}


exports.gaCosmo = gaCosmo;
