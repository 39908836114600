const adsOnIn = {
    adsOnInfinitescroll: (channel,myid) => {      
      var uri = (channel=="Love & Sex") ? 'Love_sex' : 'General'; 
      
      //Multiple leaderboard      
      googletag.cmd.push(function() {
      //override nama div menjadi Random ID 
        var randNumberLdb = Math.floor((Math.random() * 10000000) + 1);
        var el = document.getElementById("div-billboard");
        if(typeof el !== 'undefined' && el !== null) { 
          var leaderboard = "billboard-"+randNumberLdb; 
          el.id = leaderboard;        
          //Adslot 1 declaration
          gptadslots['leaderboard'] = googletag.defineSlot('/59447564/Cosmopolitan.co.id/'+uri, [[728,90]],leaderboard)
          .setTargeting('pos', ['Billboard'])
          .defineSizeMapping(mapping3)
          .setTargeting('refresh', ['true'])
          .addService(googletag.pubads());

          googletag.display(leaderboard);
          googletag.pubads().refresh([gptadslots['leaderboard']]);
        }
      });      

      for (let i = 0; i <=2; i++) {
        googletag.cmd.push(function() {
        //override nama div menjadi Random ID 
          var randNumbers = Math.floor((Math.random() * 10000000) + 1);
          var elemn = document.getElementById("div-gpt-ad-temp-multis"); 
          if(typeof elemn !== 'undefined' && elemn !== null) { 
            var multi_banner = "multi_banner-"+randNumbers;
            elemn.id = multi_banner;        
            //Adslot 1 declaration
            gptadslots['multi_banner1'] = googletag.defineSlot('/59447564/Cosmopolitan.co.id/'+uri, [[728,90]],multi_banner)
            .setTargeting('pos', ['Multi_Banner1'])
            .defineSizeMapping(mapping3)
            .setTargeting('refresh', ['true'])
            .addService(googletag.pubads());

            googletag.display(multi_banner);
            googletag.pubads().refresh([gptadslots['multi_banner1']]);
          }
        });
      }

      for (let i = 0; i <=2; i++) {
        googletag.cmd.push(function() {
        //override nama div menjadi Random ID 
          var randNumber = Math.floor((Math.random() * 10000000) + 1);
          var elemn = document.getElementById("div-gpt-ad-multi_banner2");
          if(typeof elemn !== 'undefined' && elemn !== null) { 
            var multi_banner2 = "multi_banner2-"+randNumber;
            elemn.id = multi_banner2;        
            //Adslot 1 declaration
            gptadslots['multi_banner2'] = googletag.defineSlot('/59447564/Cosmopolitan.co.id/'+uri, [[728,90]],multi_banner2)
            .setTargeting('pos', ['Multi_Banner2'])
            .defineSizeMapping(mapping3)
            .setTargeting('refresh', ['true'])
            .addService(googletag.pubads());

            googletag.display(multi_banner2);
            googletag.pubads().refresh([gptadslots['multi_banner2']]);
          }
        });
      } 

      googletag.cmd.push(function() {
      //override nama div menjadi Random ID 
        var randNumberMR1 = Math.floor((Math.random() * 10000000) + 1);
        var elemnMR1 = document.getElementById("div-gpt-ad-mr1");
        if(typeof elemnMR1 !== 'undefined' && elemnMR1 !== null) { 
          var mr1 = "mr1-"+randNumberMR1;
          elemnMR1.id = mr1;        
          //Adslot 1 declaration
          gptadslots['mr1'] = googletag.defineSlot('/59447564/Cosmopolitan.co.id/'+uri, [[728,90]],mr1)
          .setTargeting('pos', ['MR1'])
          .defineSizeMapping(mapping4)
          .setTargeting('refresh', ['true'])
          .addService(googletag.pubads());

          googletag.display(mr1);
          googletag.pubads().refresh([gptadslots['mr1']]);
        }
      }); 

      googletag.cmd.push(function() {
      //override nama div menjadi Random ID 
        var randNumberMR2 = Math.floor((Math.random() * 10000000) + 1);
        var elemnMR2 = document.getElementById("div-gpt-ad-mr2");
        if(typeof elemnMR2 !== 'undefined' && elemnMR2 !== null) { 
          var mr2 = "mr2-"+randNumberMR2;
          elemnMR2.id = mr2;        
          //Adslot 1 declaration
          gptadslots['mr2'] = googletag.defineSlot('/59447564/Cosmopolitan.co.id/'+uri, [[728,90]],mr2)
          .setTargeting('pos', ['MR2'])
          .defineSizeMapping(mapping4)
          .setTargeting('refresh', ['true'])
          .addService(googletag.pubads());

          googletag.display(mr2);
          googletag.pubads().refresh([gptadslots['mr2']]);
        }
      }); 


      /*<!--TABOOLA START-->*/ 
      window._taboola = window._taboola || [];
      _taboola.push({article:'auto'});
      !function (e, f, u, i) {
        if (!document.getElementById(i)){
          e.async = 1;
          e.src = u;
          e.id = i;
          f.parentNode.insertBefore(e, f);
        }
      }(document.createElement('script'),
      document.getElementsByTagName('script')[0],
      '//cdn.taboola.com/libtrc/mraindonesia-cosmopolitan/loader.js',
      'tb_loader_script');
      if(window.performance && typeof window.performance.mark == 'function')
        {window.performance.mark('tbl_ic');}

      /*TABOOLA RELATED ARTICLES*/
      window._taboola = window._taboola || [];
      _taboola.push({
        mode: 'thumbs-feed-01',
        container: 'taboola-below-article-thumbnails',
        placement: 'Below Article Thumbnails',
        target_type: 'mix'
      });
      
      /*<!--END/FOOTER-->*/ 
      
      window._taboola = window._taboola || [];
      _taboola.push({flush: true});
      
    }
}

exports.adsOnIn = adsOnIn;
