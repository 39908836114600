<template>
    <div>
        <div class="quantity">
            <div class="label">
                Quantity
            </div>
            <div class="controller">
                <img @click="min()" class="btn" src="/assets/Icon - Minus.svg">
                {{ quantity }}
                <img @click="add()" class="btn" src="/assets/Icon - Plus.svg">
            </div>
        </div>
        <div class="buttons">
            <div class="button-add">
                <button id="btnAddToCart" @click="addToCart" class="button-box" :disabled="isLoading"><span class="button-caption">ADD TO CART</span></button>
            </div>
            <div class="button-save-share hide-on-mobile">
                <!--<img src="{{ asset('assets/mra-icon-article-save.png') }}">-->
                <img src="/assets/mra-icon-article-share.png">
            </div>

        </div>
        <signup-dialog-component ref="signup"></signup-dialog-component>
        <popup-message-component ref="popup"></popup-message-component>
    </div>
</template>

<script setup>
import {ref} from "vue";
import SignupDialogComponent from "./SignupDialogComponent";

    const props = defineProps(['productId','token'])
    const quantity = ref(1);
    const isLoading = ref(false);
    const signup = ref(null);
    const popup = ref(null);

    function addToCart(){
        console.log('Adding...');
        isLoading.value = true;

        const payload = {
            quantity: quantity.value
        }
        axios.post('/api/v1/add-to-cart/' + props.productId + '?token=' + props.token, payload).then((res) => {
            console.log(res.data);
            popup.value.setMessage('Item have been added.')
            popup.value.showPopup();
        }).catch((error)=>{
            console.log(error.response.status);
            if(error.response.status === 401){
                signup.value.show();
            }else{
                alert('Oops! We currently have technical issue, please try again later');
            }
        }).finally(()=>{
            isLoading.value = false;
        });
    }

    function add(){
        quantity.value++;
    }

    function min(){
        if(quantity.value > 1){
            quantity.value--;
        }
    }
</script>

<style scoped>
#btnAddToCart:disabled{
    background: white;
    color: #E63C28;
}
#btnAddToCart:disabled .button-caption:before{
    content:"";
    background: url('/assets/loading.gif') left no-repeat;
    width: 10px;
    height: 10px;
    padding-left: 30px;
}

</style>
