<template>
    <div v-if="isShow === true" class="popup" @click="onClickAnywhere($event)" >
        <div class="container">
            <div class="dialog-caption">
                Sign In to Cosmopolitan.com with Google
            </div>
            <div>
                <a :href="loginUrl" id="btnLoginGoogle" class="btn btn-light btn-block w-100" style="border: 1px solid #777">
                    Sign in with Google Login
                </a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "SignupDialogComponent",

    data(){
        return {
            isShow: false,
            popupMessage: null,
            loginUrl: null
        }
    },

    methods: {
        onClickAnywhere: function(e){
            console.log(e);

            if(e.target.id !== 'btnLoginGoogle'){
                this.isShow = false;
            }

        },

        close: function(){
          this.isShow = false;
        },

        show: function(){
            this.isShow = true;
        }
    },

    mounted() {
        this.loginUrl = '/auth/google?redirect = ' + window.location.href
    }
}
</script>

<style lang="scss" scoped>
.popup{
    position: fixed;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    background: rgba(255,255,255,0.8);
    z-index: 100000;
    display: flex;
    justify-content: center;
    justify-items: center;
    flex-direction: column;

    .container{
        text-align: center;
        padding: 20px;
        width: 300px;
        height: auto;
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .dialog-caption {
            padding-bottom: 20px;
        }
    }

    @media (max-width: 767px) {
        .container{
            width: 80%;
        }
    }
}
</style>
