import { defineStore } from 'pinia'

export const useProductStore = defineStore('product', {
    state: () => ({
        products: [],
        isLoading: false,
    }),
    getters: {
        getProducts: (state) => state.products,
    },
    actions: {
        fetch(filters, onSuccess) {
            this.isLoading = true;
            axios.get('/api/products', { params: filters }).then((res) => {
                this.products = res.data;
                this.isLoading = false;
                onSuccess();
            })
        },
    },
})
