<template>
    <div>
        <div v-for="article in this.articles">
            <article-detail-component :article-id="article.id" :token="token" @onLoaded="onArticleLoaded"></article-detail-component>
        </div>
    </div>
</template>


<script>

import ArticleDetailComponent from "./ArticleDetailComponent";

const { gaCosmo } = require('../ga');
//const { adsOnIn } = require('../adsOnInfinitescroll');

let articleLoaderTriggerClass = '.trigger-article-loader';
let articleTitleClass = '.article-title';

let articleMetas = [];

export default {
    name: "ArticleLoaderComponent",
    components: {ArticleDetailComponent},
    props: [
        'token', 'articleId'
    ],

    data(){
        return {
            articles: [
                {
                    id: this.articleId
                }

            ],
            loadedArticles: [],
            nextLoadTargetArticleId: null,
            currentArticleId: null,
            previousArticleId: null,
            articleCounter: 1,
            numberOfArticleLimit: 3,

            currentMetaIndex: 0,
        }
    },

    methods: {
        //When article is loaded from Ajax request
        onArticleLoaded: function(data){
            console.log('Article have been loaded =>');
            console.log(data.id);
            this.isArticleLoaded = true;
            this.loadedArticles.push(data.id);
            this.nextLoadTargetArticleId = data.nextArticleIds[0];

            articleMetas.push({
                'id': data.id,
                'url' : data.url,
                'title' : data.title
            });
        },

        //When article is changed (indicated by scroll passing area of certain article's title)
        onArticleChangedToNext: function(articleId, articleUrl, articleTitle, channelName){
                console.log('DOWN: change URL to article ID: ' + articleId);

                //refreshing Google Ads
                //googletag.cmd.push(function() { googletag.pubads().refresh(); })

                //change URL to shown article URL
                window.history.pushState({}, '', articleUrl);
                document.title = articleTitle;
                //manually trigger GA page view
                gaCosmo.sendPageview(articleUrl,articleTitle);

                //Ads infinite scroll
                //adsOnIn.adsOnInfinitescroll(channelName);
        },

        onArticleChangedToPrev: function(previousArticleId){

            //get the article meta with id of previousArticleId
            let meta = articleMetas.filter(function(item){
                return parseInt(item.id) === parseInt(previousArticleId);
            })[0];

            window.history.pushState({}, '', meta.url);
            document.title = meta.title;
        },


        /* private functions */
        _handleScroll: function(event) {
            let t = this;
            this._handleUrlReplacement(event);

            /*
            * if marker is visible on screen, trigger the load article process
            * after that add the ID to array so that it only triggered once
            * then remove the trigger so that it's not triggered many times
            */
            let isDividerVisible = isInViewport($(articleLoaderTriggerClass));
            //console.log(isDividerVisible);

            if(isDividerVisible){
                console.log('Divider visible, loading new article');
                if(!t.loadedArticles.includes(t.nextLoadTargetArticleId)){
                    if(this.articleCounter <= this.numberOfArticleLimit){
                        t.loadedArticles.push(t.nextLoadTargetArticleId);
                        t.articles.push({
                            id: t.nextLoadTargetArticleId
                        });
                        this.articleCounter++;

                        $(articleLoaderTriggerClass).remove();
                    }
                }
            }
        },
        _handleUrlReplacement: function(event){
            let t = this;
            let titleVisible = $('.article-title');

            //run thru all article title
            titleVisible.each(function(){
                let el = $(this);
                if(isInViewport(el)){

                    if(getScrollDirection() === Direction.DIRECTION_DOWN){

                        if(t._isIdChanged(el.attr('data-id'))){
                            t.previousArticleId = t.currentArticleId;
                            t.currentArticleId = el.attr('data-id');

                            t.onArticleChangedToNext(el.attr('data-id'), el.attr('data-url'), el.attr('data-title'), el.attr('data-channel'));
                        }
                    }else{
                        if(t.currentArticleId !== t.previousArticleId){

                            t.currentArticleId = t.previousArticleId;
                            t.onArticleChangedToPrev(t.currentArticleId);
                        }

                    }

                }
            })
        },
        _isIdChanged: function(idToCheck){
            return parseInt(idToCheck) !== parseInt(this.currentArticleId);
        },




},

    created () {
        window.addEventListener('scroll', this._handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this._handleScroll);
    },



}

function isInViewport(element) {

    if(element.get(0)){
        const rect = element.get(0).getBoundingClientRect();
        return (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight));
    }

}



let Direction = {
    DIRECTION_UP: "UP",
    DIRECTION_DOWN: 'DOWN'
}
let prevScrollPosTop = 0;

function getScrollDirection() {

    let direction;
    let currentTop = $('body').get(0).getBoundingClientRect().top;
    if(currentTop > prevScrollPosTop){
        direction = Direction.DIRECTION_UP;
    }else{
        direction = Direction.DIRECTION_DOWN;
    }
    prevScrollPosTop = currentTop;

    return direction;

}
</script>

<style scoped>

</style>
