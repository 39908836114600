<template>
    <div class="text-center py-5" v-if="!isLoaded">
        Loading...
    </div>
    <div v-else class="row main">
        <div v-for="product in this.products" class="col-6 col-lg-3 content-items">
            <div class="img-container">
                <a :href="product.url"><img class="img-fill-content" :src="product.image"></a>
            </div>
            <div class="brand-container">
                {{ product.brand.name }}
            </div>
            <div class="name-container">
                <a :href="product.url">{{ product.name }}</a>
            </div>
            <div class="price-container">
                {{ product.price }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
    import {useProductStore} from "../storages/product";


    const store = useProductStore();
    const isLoaded = ref(false);
    const products = computed(() => {
        return store.products;
    });

    store.$subscribe((mutation, state) => {
        if(mutation.events.key === 'isLoading'){
            isLoaded.value = !mutation.events.newValue;
        }
    });


    store.fetch({}, () => {
        isLoaded.value = true;
    });

    onMounted(() => {
       console.log(route);
    });

</script>

<style scoped>

</style>
