<template>
    <div v-if="hasTrivia">
        <div v-if="isLoaded">
            <section v-if="!isCompleted" class="section-article-quiz" id="section-article-quiz" style="background-color: #D2EBE6;">
                <div class="container">
                    <div class="header">
                        {{ parseInt(activeQuestionIndex) + 1}} / {{ trivia.totalQuestion }}
                    </div>

                    <div class="image">
                        <img :src="trivia.banner" />
                    </div>
                    <div v-if="isStart">
                        <div class="question">
                            {{ activeQuestion.question }}
                        </div>
                        <div class="answers-container">
                            <div v-for="option in activeQuestion.options" @click="optionClick(option.id, activeQuestion.id)" class="answer-content trivia-option component-trivia-option">
                                {{ option.option }}
                            </div>
                        </div>
                    </div>
                    <div v-else class="start-screen">
                        <h3 class="trivia-name">{{ trivia.name }}</h3>
                        <button class="btn btn-primary" @click="startQuiz()">
                            START NOW
                        </button>
                    </div>

                </div>
            </section>

            <section v-else id="section-article-quiz-result" style="background-color: #D2EBE6;">
                <div class="container">

                    <div class="header">
                        <h3>Your Result</h3>
                    </div>

                    <div class="main-result">
                        <div v-if="submitCompleted">
                            <h2>Your Total score is {{ percentageScore }}%.</h2>
                            <div>
                                Correct Answer: {{ totalCorrectAnswer }} of {{ trivia.totalQuestion }}
                            </div>
                        </div>
                        <div v-else>
                            <i class="fa fa-spin fa-spinner"></i> Checking your answers...
                        </div>
                    </div>

                    <div class="image">
                       <img :src="trivia.banner" />
                    </div>
                    <div v-if="submitCompleted" class="submit-container">
                        <span id="btn-play-again-quiz" class="button-box" onclick="window.location.href='/article-all?with=quiz'">PLAY ANOTHER QUIZ</span>
                    </div>
                </div>
            </section>
        </div>
        <div v-else class="loading-quiz">
            <i class="fa fa-spin fa-spinner"></i> Loading Quiz...
        </div>
    </div>
    <signup-dialog-component ref="signup"></signup-dialog-component>
</template>

<script>
import SignupDialogComponent from "./SignupDialogComponent";

export default {
    name: "TriviaComponent",
    props: ['triviaId','token'],

    components:[
        SignupDialogComponent
    ],

    data(){
        return {
            localTriviaId: null,
            trivia: {
                questions: {
                    options: {}
                },
            },
            activeQuestion: {},
            activeQuestionIndex: 0,
            isCompleted: false,
            submitCompleted: false,
            answers: [],
            totalCorrectAnswer: 0,
            percentageScore: 0,
            hasTrivia: false,
            isLoaded: false,
            isStart: false,
        }
    },

    methods:{
        optionClick: function(optionId, questionId){
            console.log('on question: ' + questionId);
            console.log('choose option: ' + optionId);

            this.answers.push({
               questionId,
               optionId
            });

            //a bit of delay to improve UX
            setTimeout(()=> { this.nextQuestion(); },300)


        },

        onTriviaCompleted: function(answers, triviaId){

            let t = this;

            let payload = {
                answers,
                triviaId,
                token: this.token
            }

            axios.post('/api/trivia/user/send', payload).then((res) => {
                console.log(res.data);
                this.totalCorrectAnswer = res.data.totalCorrectAnswer;
                this.percentageScore = res.data.percentageScore;
            }).catch((error) => {
                //unauthorized access, meaning not logged yet
                if(error.response.status === 401){
                    t.$refs.signup.show();
                }
            }).finally(() => {
                this.submitCompleted = true;
            });
        },

        startQuiz: function(){
          this.isStart = true;
        },

        nextQuestion: function(){
            if(this.activeQuestionIndex < this.trivia.totalQuestion - 1){
                this.activeQuestionIndex += 1;
                this.activeQuestion = this.trivia.questions[this.activeQuestionIndex];
            }else{
                this.onTriviaCompleted(this.answers, this.localTriviaId);
                this.isCompleted = true;
            }

        }
    },

    mounted() {
        this.hasTrivia = !isNaN(parseInt(this.triviaId));

        console.log("Trivia ID: " + this.triviaId);

        if(this.triviaId){
            this.localTriviaId = this.triviaId;

            axios.get('/api/trivia/' + this.localTriviaId + '?token=' + this.token).then((res) => {
                this.isLoaded = true;
                this.trivia = res.data

                this.activeQuestion = this.trivia.questions[this.activeQuestionIndex];
            });
        }

    }

}
</script>

<style scoped>
    .header{
        text-align: center;
        display: block !important;
    }

    .component-trivia-option:active{
        background: #E63C28 !important;
        color: white !important;
    }

    .loading-quiz{
        padding-bottom: 50px;
    }

    .start-screen{
        padding: 50px 0;
    }

    .btn-primary{
        background: #E63C28 !important;
        border: 0;
        padding: 15px 25px;
    }

    .trivia-name{
        margin-bottom: 50px;
    }
</style>
